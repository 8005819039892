<template>
  <v-row>
    <v-col class="mb-4">
      <v-alert type="success" dismissible close-text="Close Alert">
        Last imported invoice date was {{latestXeroUpdate}}, Go to Xero -> Invoices to refresh Xero data if necessary
      </v-alert>
      <router-view class="view" />
    </v-col>
  </v-row>
</template>

<script>
import ReportApi from '@/api/admin/report'
import moment from 'moment'
export default {
  data () {
    return {
      latestXeroUpdate: null
    }
  },
  async created () {
    ReportApi.getLatestXeroUpdate().then((results) => {
      this.latestXeroUpdate = moment(results.data.invoiceDate).format('D MMMM YYYY')
    })
  }
}
</script>
